.info {
  width: 100%;
}
.info_box {
  width: 100%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* MAIN */
.ib_head_box {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ib_body_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* TITLE */
.ib_hb_title {
  width: 100%;
  margin: 0;
  font-size: 24px;
  font-family: "Comfortaa", cursive;
  color: #000000;
}
.ib_hb_icon {
  width: 40px;
  margin-right: 10px;
}
/* BODY */
.ib_bb_slider_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ib_bb_sb_slider {
  width: 80%;
  height: 280px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: relative;
}
/* BODY DETAILS */
.ib_bb_sb_s_image_box {
  padding: 20px 0 20px 20px;
  transform: translate(-70px, 0);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.ib_bb_sb_s_text_box {
  padding: 20px 20px 20px 0;
  margin: 0 auto 0 0;
  overflow: hidden;
}
.ib_bb_sb_s_move_box {
  padding: 20px;
}

.ib_bb_sb_s_ib_image {
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 0 10px #e2e2e2;
  filter: grayscale(100%) brightness(0.6);
}

.ib_bb_sb_s_tb_logo {
  width: 50px;
}
.ib_bb_sb_s_tb_title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 10px 0;
  font-family: "Comfortaa", cursive;
}
.ib_bb_sb_s_tb_text {
  width: 90%;
  font-size: 16px;
  margin: 0;
  padding: 10px 0;
  color: #7a7a7a;
  font-family: "Comfortaa", cursive;
}

.ib_bb_sb_s_mb_number {
  width: 10px;
  height: 10px;
  margin: 10px 0;
  border-radius: 50px;
  background-color: #e2e2e2;
  box-shadow: 0 0 4px #e2e2e2;
  cursor: pointer;
}
.ib_bb_sb_s_mb_active {
  height: 30px;
  background-color: #ff4800;
  box-shadow: 0 0 4px #ff4800;
}

/* SQUARES */
.ib_bb_sb_s_ib_square_box_botton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.ib_bb_sb_s_ib_square_box_top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
}
.ib_bb_sb_s_ib_sb_row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ib_bb_sb_s_ib_sb_c_square {
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: #ff4800;
}

@media screen and (max-width: 1200px) {
  .ib_bb_sb_slider {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .ib_bb_sb_s_tb_logo {
    width: 40px;
  }
  .ib_bb_sb_s_tb_title {
    font-size: 16px;
  }
  .ib_bb_sb_s_tb_text {
    font-size: 14px;
  }
  .ib_bb_sb_slider {
    height: 240px;
  }
  .ib_hb_title {
    font-size: 18px;
  }
  .ib_hb_icon {
    width: 30px;
  }
  .ib_bb_sb_s_image_box {
    display: none;
  }
  .ib_bb_sb_s_text_box {
    padding: 10px 0 10px 40px;
    margin: 0;
  }
}

@media screen and (max-width: 700px) {
  .ib_bb_sb_s_ib_image {
    width: 150px;
  }
}
