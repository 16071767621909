.skills {
  width: 100%;
}
.skills_box {
  width: 80%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sb_head_box {
  width: 100%;
  margin: 20px 0;
}
.sb_body_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sb_tb_title {
  width: 100%;
  margin: 0;
  font-size: 32px;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: #ff4800;
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 800px) {
  .skills_box {
    width: 90%;
  }
}
