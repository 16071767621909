.go_up {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 99;
}
.show {
  transform: scale(1, 1);
  opacity: 1;
  transition: 400ms;
}
.hide {
  transform: scale(0.001, 0.001);
  opacity: 0;
  transition: 400ms;
}
.go_up_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.gub_up_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 0 5px;
  backdrop-filter: saturate(180%) blur(5px);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 6px #c1c1c1;
  border-radius: 50px;
  transition: 300ms;
}
.gub_up_box:hover {
  transform: scale(0.9);
  box-shadow: 0px 0px 4px #c1c1c1;
}

.gub_call_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 0 5px;
  backdrop-filter: saturate(180%) blur(5px);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 6px #c1c1c1;
  border-radius: 50px;
  transition: 300ms;
}
.gub_call_box:hover {
  transform: scale(0.9);
  box-shadow: 0px 0px 4px #c1c1c1;
}
.gub_cb_icon_box {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
}
.gub_cb_icon_box_open {
  width: 150px;
  transition: 200ms;
}

.gub_ub_icon {
  width: 20px;
}
.gub_cb_ib_icon {
  width: 30px;
}
.gub_cb_ib_mobile {
  margin: 0;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Comfortaa", cursive;
  transition: 200ms;
}
.gub_cb_ib_mobile:hover {
  text-decoration: underline;
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
