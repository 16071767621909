.knowledge {
  width: 100%;
}
.knowledge_box {
  width: 100%;
  /* margin: 10px auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* MAIN */
.kb_head_box {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kb_body_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* TITLE */
.kb_hb_title {
  width: 100%;
  margin: 0;
  font-size: 24px;
  font-family: "Comfortaa", cursive;
  color: #000000;
}
.kb_hb_icon {
  width: 40px;
  margin-right: 10px;
}
/* BODY */
.kb_bb_slider_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.kb_bb_sb_slider {
  width: 80%;
  /* height: 260px; */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: relative;
}
/* BODY DETAILS */
.kb_bb_sb_s_slider_piece {
  padding: 20px;
}
.kb_bb_sb_s_text_box {
  padding: 20px;
  margin: 0 auto 0 0;
  overflow: hidden;
}
.kb_bb_sb_s_tb_ability_box {
  padding: 20px;
  border-left: 1px solid #e2e2e2;
}

.kb_bb_sb_s_sp_piece {
  width: 10px;
  height: 10px;
  margin: 10px 0;
  border-radius: 50px;
  background-color: #e2e2e2;
  box-shadow: 0 0 4px #e2e2e2;
  cursor: pointer;
}
.kb_bb_sb_s_sp_piece_active {
  height: 30px;
  background-color: #ff4800;
  box-shadow: 0 0 4px #ff4800;
}

.kb_bb_sb_s_tb_logo {
  width: 50px;
}
.kb_bb_sb_s_tb_title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px 0;
  font-family: "Comfortaa", cursive;
}
.kb_bb_sb_s_tb_text {
  font-size: 16px;
  margin: 0;
  padding: 10px 0;
  font-family: "Comfortaa", cursive;
  color: #7a7a7a;
}
.kb_bb_sb_s_tb_detail_box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0;
}
.kb_bb_sb_s_tb_db_bb_button {
  outline: none;
  border: none;
  padding: 10px 20px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.kb_bb_sb_s_tb_ab_circle_box {
  width: 180px;
  height: 180px;
  position: relative;
}
.kb_bb_sb_s_tb_ab_cb_text_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.kb_bb_sb_s_tb_ab_cb_title {
  width: max-content;
  margin: 0;
  font-size: 16px;
  color: #000000;
  text-align: center;
  font-family: "Comfortaa", cursive;
}
.kb_bb_sb_s_tb_ab_cb_percent {
  margin: 0;
  font-size: 18px;
  color: #000000;
  text-align: center;
  font-family: "Comfortaa", cursive;
}

.kb_bb_sb_s_tb_ab_circle_box_mobile_device {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
}
.kb_bb_sb_s_tb_ab_cb_percent_mobile_device {
  margin: 0;
  font-size: 12px;
  color: #000000;
  text-align: center;
  font-family: "Comfortaa", cursive;
}

@media screen and (max-width: 800px) {
  .kb_bb_sb_s_tb_logo {
    width: 40px;
  }
  .kb_bb_sb_s_tb_title {
    font-size: 20px;
  }
  .kb_bb_sb_s_tb_text {
    font-size: 14px;
  }
  .kb_bb_sb_s_slider_piece {
    padding: 5px;
  }
  .kb_bb_sb_s_tb_ability_box {
    display: none;
  }
  .kb_bb_sb_s_tb_ab_circle_box_mobile_device {
    display: unset;
  }
  .kb_hb_title {
    font-size: 18px;
  }
  .kb_hb_icon {
    width: 30px;
  }
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 1200px) {
  .kb_bb_sb_slider {
    width: 100%;
  }
}
