body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbfb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"), url(./assets/Fonts/Comfortaa.ttf) format("truetype");
}
@font-face {
  font-family: "Dosis";
  src: local("Dosis"), url(./assets/Fonts/Dosis.ttf) format("truetype");
}
@font-face {
  font-family: "GloriaHallelujah";
  src: local("GloriaHallelujah"),
    url(./assets/Fonts/GloriaHallelujah.ttf) format("truetype");
}
@font-face {
  font-family: "Sacramento";
  src: local("Sacramento"),
    url(./assets/Fonts/Sacramento.ttf) format("truetype");
}
