.contact {
  width: 100%;
}
.contact_box {
  width: 80%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* MAIN */
.cb_head_box {
  width: 100%;
  margin: 20px 0;
}
.cb_body_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* TITLE */
.cb_hb_title {
  width: 100%;
  margin: 0;
  font-size: 32px;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: #ff4800;
}
/* BODY */
.cb_bb_detail_box {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cb_bb_input_box {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* BODY details */
/* LOGO */
.cb_bb_db_thimoty_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Comfortaa", cursive;
}
.cb_bb_db_social_box {
  /* width: 100%; */
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto;
  padding: 20px 0;
}

.cb_bb_db_tb_logo {
  width: 200px;
}
.cb_bb_db_tb_intro_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cb_bb_db_tb_ib_title {
  margin: 0;
  padding: 10px 0;
  font-size: 24px;
  font-weight: 600;
}
.cb_bb_db_tb_ib_text {
  margin: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #7a7a7a;
}
.cb_bb_db_sb_social {
  width: 64px;
  height: 64px;
  background-color: #ff4800;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  position: relative;
  cursor: pointer;
}
.cb_bb_db_sb_social_location_1 {
  transform: translate(-50%, -25%);
  cursor: default;
}
.cb_bb_db_sb_social_location_2 {
  transform: translate(0%, -50%);
}
.cb_bb_db_sb_s_inner {
  width: 60px;
  height: 60px;
  background-color: #ff4800;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.cb_bb_db_sb_s_inner_thimoty {
  background-color: #ffffff;
}
.cb_bb_db_sb_s_inner:hover {
  background-color: #ffffff;
}
.cb_bb_db_sb_s_i_social {
  width: 50%;
}
.cb_bb_db_sb_s_i_social_text {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  transform: rotate(30deg);
  color: #ff4800;
  font-family: "Sacramento", cursive;
}
/* CEILS */
.cb_bb_ib_first_ceil {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cb_bb_ib_second_ceil {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cb_bb_ib_third_ceil {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cb_bb_ib_forth_ceil {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* CEIL details */
.cb_bb_ib_fc_full_input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.cb_bb_ib_fc_miny_input {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.cb_bb_ib_fc_mi_input {
  width: 100%;
  padding: 12px;
  outline: none;
  border: 2px solid transparent;
  box-shadow: 0 0 10px #efe8e0;
  border-radius: 5px;
  transition: 200ms;
  font-family: "Comfortaa", cursive;
}
.cb_bb_ib_fc_mi_input:hover {
  box-shadow: 0 0 2px #efe8e0;
  transform: translate(0, 4px);
  color: #ff4800;
}
.cb_bb_ib_fc_mi_input:focus {
  box-shadow: 0 0 2px #efe8e0;
  transform: translate(0, 2px);
  border: 2px solid #ff4800;
  color: #ff4800;
}
.cb_bb_ib_sc_mi_input {
  width: 100%;
  padding: 12px;
  outline: none;
  border: 2px solid transparent;
  box-shadow: 0 0 10px #efe8e0;
  border-radius: 5px;
  transition: 200ms;
  font-family: "Comfortaa", cursive;
}
.cb_bb_ib_sc_mi_input:hover {
  box-shadow: 0 0 2px #efe8e0;
  transform: translate(0, 4px);
  color: #ff4800;
}
.cb_bb_ib_sc_mi_input:focus {
  box-shadow: 0 0 2px #efe8e0;
  transform: translate(0, 2px);
  border: 2px solid #ff4800;
  color: #ff4800;
}
.cb_bb_ib_tc_input {
  width: 100%;
  height: 240px;
  resize: none;
  padding: 12px;
  outline: none;
  border: 2px solid transparent;
  box-shadow: 0 0 10px #efe8e0;
  border-radius: 5px;
  font-family: "Comfortaa", cursive;
  transition: 200ms;
}
.cb_bb_ib_tc_input:hover {
  box-shadow: 0 0 2px #efe8e0;
  transform: translate(0, 4px);
  color: #ff4800;
}
.cb_bb_ib_tc_input:focus {
  box-shadow: 0 0 2px #efe8e0;
  transform: translate(0, 2px);
  border: 2px solid #ff4800;
  color: #ff4800;
}
.cb_bb_ib_fc_button {
  padding: 10px 20px;
  border: none;
  outline: none;
  color: #000000;
  background-color: transparent;
  font-size: 18px;
  font-family: "Comfortaa", cursive;
  transition: 200ms;
  cursor: pointer;
}
.cb_bb_ib_fc_button:hover {
  letter-spacing: 3px;
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.highlight::-moz-selection {
  color: #ffffff;
  background: #ff4800;
}
.highlight::selection {
  color: #ffffff;
  background: #ff4800;
}

@media screen and (max-width: 1200px) {
  .cb_body_box {
    flex-direction: column;
  }
  .cb_bb_detail_box {
    width: 100%;
  }
  .cb_bb_input_box {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .contact_box {
    width: 90%;
  }
  .cb_bb_db_sb_s_inner {
    background-color: #ffffff;
  }
}

@media screen and (max-width: 550px) {
  .cb_bb_db_tb_logo {
    width: 140px;
  }

  .cb_bb_db_tb_ib_title {
    font-size: 20px;
  }
  .cb_bb_db_tb_ib_text {
    font-size: 14px;
  }
}
