.toolbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}
.show {
  transform: scale(1, 1);
  opacity: 1;
  transition: 800ms;
}
.hide {
  transform: scale(0.001, 0.001);
  opacity: 0;
  transition: 800ms;
}
.toolbar_box {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
}

.tb_logo_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 10px 0;
  padding: 10px;
  backdrop-filter: saturate(180%) blur(5px);
  box-shadow: 0px 0px 10px #c1c1c1;
  border-radius: 50px;
}

.tb_more_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 15px;
  backdrop-filter: saturate(180%) blur(5px);
  box-shadow: 0px 0px 10px #c1c1c1;
  border-radius: 50px;
  cursor: pointer;
}

.tb_lb_logo {
  width: 32px;
  margin-right: 10px;
}
.tb_lb_title {
  margin: 0;
  font-size: 18px;
  font-family: "Comfortaa", cursive;
}
.tb_lb_line_through {
  text-decoration: line-through;
}

.tb_mb_icon {
  width: 16px;
}

/* TOOLBOX */
.tool_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: saturate(180%) blur(5px);
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toolbox {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolbox_box {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.tb_logo {
  width: 180px;
  margin-bottom: 50px;
  cursor: pointer;
}
.tb_option {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  font-family: "Comfortaa", cursive;
  margin: 10px 0;
  cursor: pointer;
  transition: 200ms;
}

.tb_option:hover {
  letter-spacing: 4px;
}
