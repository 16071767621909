.about {
  width: 100%;
}
.about_box {
  width: 80%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* MAIN */
.ab_head_box {
  width: 100%;
  margin: 20px 0;
}
.ab_body_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
/* TITLE */
.ab_hb_title {
  width: 100%;
  margin: 0;
  font-size: 32px;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: #ff4800;
}
/* BODY */
/* BODY DETAIL */
.ab_bb_base_box {
  width: 40%;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 2px #e1e1e1;
  backdrop-filter: saturate(100%) blur(2px);
}
.ab_bb_bb_base_box_2 {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 2px #e1e1e1;
  backdrop-filter: saturate(180%) blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/*1*/
.ab_bb_bb_bb2_intro_myself {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: all 1s;
}
.ab_bb_bb_bb2_intro_myself_change {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto 0 0;
  padding: 20px;
  transition: all 1s;
}
.ab_bb_bb_bb2_im_image {
  width: 100px;
  border-radius: 50px;
  transition: 0.5s;
  box-shadow: 0 0 10px #e1e1e1;
}
.ab_bb_bb_bb2_im_image_change {
  width: 60px;
  border-radius: 50px;
  transition: 0.5s;
  box-shadow: 0 0 10px #e1e1e1;
}
.ab_bb_bb_bb2_im_text_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-family: "Comfortaa", cursive;
}
.ab_bb_bb_bb2_im_text_box_change {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 20px;
}
.ab_bb_bb_bb2_im_tb_name {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 18px;
  font-weight: 600;
}
.ab_bb_bb_bb2_im_tb_title {
  margin: 0;
  font-size: 12px;
  padding-top: 4px;
  color: #7a7a7a;
}
/*ABOUT*/
.ab_bb_bb_bb2_explane_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}
.ab_bb_bb_bb2_eb_explain_title {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 18px;
  font-weight: 600;
  font-family: "Comfortaa", cursive;
}
.ab_bb_bb_bb2_eb_explain_text {
  margin: 0;
  font-size: 14px;
  padding: 10px 0 0 0;
  font-family: "Comfortaa", cursive;
  color: #7a7a7a;
}
.ab_bb_bb_bb2_eb_social_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
}
.ab_bb_bb_bb2_eb_sb_social {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 10px #e1e1e1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 200ms;
}
.ab_bb_bb_bb2_eb_sb_social:hover {
  box-shadow: 0 0 4px #e1e1e1;
  transform: translate(0, 4px);
}
.ab_bb_bb_bb2_eb_sb_s_logo {
  width: 64%;
}
/* WORK EXPERIENCE */
.ab_bb_bb_bb2_experience_myself {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 20px;
}
.ab_bb_bb_bb2_em_title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  font-family: "Comfortaa", cursive;
}
.ab_bb_bb_bb2_em_body_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  font-family: "Comfortaa", cursive;
}
.ab_bb_bb_bb2_em_bb_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
}
.ab_bb_bb_bb2_em_bb_t_point {
  width: 12px;
  margin-right: 10px;
}
.ab_bb_bb_bb2_em_bb_t_text {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.ab_bb_bb_bb2_em_bb_description {
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 14px;
  color: #7a7a7a;
}
.ab_bb_bb_bb2_em_bb_t_date {
  position: absolute;
  right: 30px;
  top: 15px;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}
.ab_bb_bb_bb2_em_bb_d_line {
  position: absolute;
  left: 25px;
  top: 26px;
  width: 2px;
  height: 80%;
  border-radius: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(255, 255, 255, 0));
}
/* CONTACT */
.ab_bb_bb_bb2_contact_myself {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.ab_bb_bb_bb2_cm_title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  font-family: "Comfortaa", cursive;
}
.ab_bb_bb_bb2_cm_bb_contact_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  font-family: "Comfortaa", cursive;
}
.ab_bb_bb_bb2_cm_bb_cb_icon {
  width: 20px;
  padding: 0 20px;
  border-right: 1px solid #e1e1e1;
}
.ab_bb_bb_bb2_cm_bb_cb_text {
  margin: 0;
  padding: 0 20px;
  transition: 200ms;
  cursor: pointer;
}
.ab_bb_bb_bb2_cm_bb_cb_text:hover {
  text-decoration: underline;
}
.ab_bb_bb_bb2_cm_bb_button_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ab_bb_bb_bb2_cm_bb_button {
  width: 100%;
  padding: 12px;
  margin: 20px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  font-family: "Comfortaa", cursive;
}

/*3*/
.ab_bb_bb_bb2_toolbar_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Comfortaa", cursive;
}
.ab_bb_bb_bb2_tb_option {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
.ab_bb_bb_bb2_tb_option_active {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 72, 0, 0.1)
  );
  border-bottom: 3px solid #ff4800;
  color: #ff4800;
}

.dont_show {
  display: none;
}
.outer_box_1 {
  position: absolute;
  top: 100px;
  left: -20px;
  z-index: -1;
  width: 50%;
  height: 100px;
  border: 8px solid #000000;
  background-color: transparent;
  box-shadow: 0 0 10px #000000;
}
.outer_box_2 {
  position: absolute;
  top: 250px;
  right: -20px;
  z-index: -1;
  width: 50%;
  height: 100px;
  border: 8px solid #000000;
  background-color: transparent;
  box-shadow: 0 0 10px #000000;
}
.outer_box_3 {
  position: absolute;
  top: -30px;
  right: 15%;
  z-index: -1;
  width: 20%;
  height: 200px;
  border: 8px solid #000000;
  background-color: transparent;
  box-shadow: 0 0 10px #000000;
}
.outer_box_4 {
  position: absolute;
  top: 260px;
  left: 20%;
  z-index: -1;
  width: 10%;
  height: 150px;
  border: 8px solid #000000;
  background-color: transparent;
  box-shadow: 0 0 10px #000000;
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 1100px) {
  .ab_bb_base_box {
    width: 60%;
  }
}
@media screen and (max-width: 680px) {
  .ab_bb_base_box {
    width: 80%;
  }
}
@media screen and (max-width: 550px) {
  .ab_bb_base_box {
    width: 100%;
  }
  .ab_bb_bb_bb2_tb_option {
    font-size: 14px;
  }
}
