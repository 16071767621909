.amaze {
  width: 100%;
  background-image: url("../../assets/Images/amaze.jpg");
  background-attachment: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: brightness(0.8); */
  box-shadow: 0 0 20px #000000;
}
.amaze_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ab_text_box {
  width: 100%;
  height: 400px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Comfortaa", cursive;
}

.ab_tb_title {
  margin: 0;
  padding: 10px 0;
  font-size: 24px;
  text-align: center;
}
.ab_tb_text {
  margin: 0;
  padding: 10px 0;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}
.ab_tb_button {
  outline: none;
  border: none;
  background-color: #000000;
  color: #ffffff;
  border-radius: 10px;
  margin: 10px 0;
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  font-family: "Comfortaa", cursive;
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
