.entertainment {
  width: 100%;
}
.entertainment_box {
  width: 80%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.eb_head_box {
  width: 100%;
  margin: 20px 0;
}
.eb_body_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.eb_hb_title {
  width: 100%;
  margin: 0;
  font-size: 32px;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: #ff4800;
}

.eb_result_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.eb_active_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.eb_hobby_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.eb_refresh_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.eb_rb_star_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}
.eb_rb_num_of_move_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.eb_rb_sb_star {
  width: 18px;
  margin: 0 5px;
}
.eb_rb_nomb_number {
  margin: 0;
  font-size: 16px;
  color: #000000;
  font-family: "Comfortaa", cursive;
}

.eb_ab_column {
  width: 100px;
  height: 20px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eb_hb_each_tree_box {
  width: 100px;
  height: 140px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.eb_hb_etb_column_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
}
.eb_hb_etb_roof_box {
  width: 100%;
}

.eb_hb_etb_cb_main_column {
  width: 4px;
  height: 100%;
  border-radius: 10px 10px 0 0;
  background-color: #000000;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: -1;
  transform: translate(-50%);
}
.eb_hb_etb_rb_roof {
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background-color: #000000;
  box-shadow: 0px 4px 10px #808080;
}

.pink {
  width: 22%;
  height: 18px;
  border-radius: 10px;
  background-color: pink;
}
.rebeccapurple {
  width: 35%;
  height: 18px;
  border-radius: 10px;
  background-color: rebeccapurple;
}
.blue {
  width: 48%;
  height: 18px;
  border-radius: 10px;
  background-color: blue;
}
.yellow {
  width: 61%;
  height: 18px;
  border-radius: 10px;
  background-color: yellow;
}
.green {
  width: 74%;
  height: 18px;
  border-radius: 10px;
  background-color: green;
}
.orange {
  width: 87%;
  height: 18px;
  border-radius: 10px;
  background-color: orange;
}
.red {
  width: 100%;
  height: 18px;
  border-radius: 10px;
  background-color: red;
}
.hide {
  width: 100%;
  height: 18px;
  border-radius: 10px;
  background-color: transparent;
  opacity: 0;
}

.eb_rb_refrech_icon {
  width: 18px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.4s;
}
.eb_rb_refrech_icon:hover {
  transform: scale(1.2) rotate(90deg);
}

.win_disable {
  opacity: 0;
}
.win_box {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  backdrop-filter: saturate(180%) blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Comfortaa", cursive;
}
.wb_text {
  margin: 0;
  font-size: 32px;
  padding-top: 10px;
}
.wb_score {
  margin: 0;
  font-size: 16px;
  color: #7a7a7a;
  padding-top: 10px;
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
