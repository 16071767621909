.beginning {
  width: 100%;
}
.beginning_box {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #212529;
}
.beginning_box:hover > .bb_image_box_without_filter {
  filter: brightness(0.6) blur(8px);
  transition: 0.8s;
}
.beginning_box:hover > .bb_text_box_immune {
  filter: blur(0);
  transition: 0.8s;
}

.bb_image_box {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/Images/main.jpg");
  background-attachment: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bb_image_box_with_filter {
  filter: brightness(0.6) blur(8px);
  transition: 0.8s;
}
.bb_image_box_without_filter {
  filter: brightness(0.6);
  transition: 0.8s;
}

.bb_text_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "GloriaHallelujah", cursive;
}
.bb_text_box_captive {
  filter: none;
  transition: 0.8s;
}
.bb_text_box_immune {
  /* filter: blur(2px); */
  transition: 0.8s;
}

.bb_tb_icon {
  width: 180px;
}
.bb_tb_first_text {
  width: 100%;
  margin: 0;
  font-size: 20px;
  color: #f8f9fa;
  text-align: center;
}
.bb_tb_second_text {
  width: 100%;
  margin: 0;
  font-size: 40px;
  color: #f8f9fa;
  text-align: center;
}
.bb_tb_third_text {
  width: 100%;
  margin: 0;
  font-size: 16px;
  color: #f8f9fa;
  text-align: center;
}
.bb_tb_type_text_box {
  width: 100%;
}
.bb_tb_type_text_box_close {
  visibility: hidden;
}
.bb_tb_ttb_text {
  width: 100%;
  margin: 0;
  font-size: 22px;
  color: #f8f9fa;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .bb_tb_first_text {
    font-size: 16px;
  }
  .bb_tb_second_text {
    font-size: 24px;
  }
  .bb_tb_third_text {
    font-size: 14px;
  }

  .bb_tb_ttb_text {
    font-size: 18px;
  }
}
@media screen and (max-width: 700px) {
  .bb_image_box {
    background-image: url("../../assets/Images/main-mobile.jpg");
    background-attachment: fixed;
  }
}
