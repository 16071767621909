.footer {
  width: 100%;
  height: 300px;
  position: relative;
}
.footer_box {
  width: 100%;
}

.fb_first_box {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  background-color: rgba(255, 72, 0, 0.9);
  clip-path: polygon(0% 90%, 100% 0%, 100% 100%, 0% 100%);
}
.fb_second_box {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  background-color: rgba(255, 72, 0, 0.7);
  clip-path: polygon(0% 0%, 100% 80%, 100% 100%, 0% 100%);
}
.fb_third_box {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  background-color: rgba(255, 72, 0, 1);
  clip-path: polygon(0% 30%, 100% 80%, 100% 100%, 0% 100%);
}

.fb_shayan_box {
  position: absolute;
  bottom: 20px;
  left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fb_text_box {
  position: absolute;
  bottom: 120px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fb_cr_box {
  position: absolute;
  bottom: 10px;
  width: 100%;
  margin: 0;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Comfortaa", cursive;
}

.fb_sb_logo {
  width: 100px;
}
.fb_sb_name {
  margin: 0;
  font-size: 30px;
  color: #ffffff;
  font-family: "Sacramento", cursive;
}

.fb_tb_text {
  margin: 0;
  font-size: 24px;
  color: #ffffff;
  font-family: "Sacramento", cursive;
}

.disable_highlight::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 800px) {
  .footer {
    height: 380px;
  }
  .fb_first_box {
    height: 380px;
    clip-path: polygon(0% 60%, 100% 0%, 100% 100%, 0% 100%);
  }
  .fb_second_box {
    height: 380px;
    clip-path: polygon(0% 0%, 100% 60%, 100% 100%, 0% 100%);
  }
  .fb_third_box {
    height: 380px;
    clip-path: polygon(0% 30%, 100% 60%, 100% 100%, 0% 100%);
  }
  .fb_shayan_box {
    bottom: 40px;
    left: 30px;
  }
  .fb_text_box {
    right: -80px;
    bottom: 150px;
    transform: rotate(90deg);
  }
}
